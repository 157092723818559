<template>
  <va-select
    v-model="selectedTeam"
    :label="$t('layout.form.teamInput')"
    :options="teamsList"
    :loading="loading || isLoading"
    :no-options-text="$t('layout.empty')"
    :disabled="disabled"
    :text-by="textBy"
    @update-search="asyncFindTeams"
    searchable
  />
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'team-bar',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withFilters: {
      type: String,
      default: '',
    },
    textBy: {
      type: String,
      default: 'label',
    },
    allSelector: {
      type: Boolean,
      default: false,
    },
    initialTeam: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  data () {
    return {
      isLoading: false,
      isError: false,
      teamsList: [],
      selectedTeam: '',
      currentQuery: '',
    }
  },
  watch: {
    initialTeam (val) {
      if (!val) return
      val.label = this.getTeamLabel(val)
      this.selectedTeam = val
    },
    selectedTeam (val) {
      this.teamSelected(val)
    },
    withFilters (val) {
      if (val !== '') {
        this.asyncFindTeams(val)
      }
    },
    'currentUser.permissions' (val) {
      this.initialData()
    },
  },
  created () {
    this.initialData()
  },
  methods: {
    fixData (teams) {
      for (const t of teams) {
        t.label = this.getTeamLabel(t)
      }
      if (this.allSelector) {
        teams.unshift({
          id: 0,
          label: this.$t('layout.selects.all_teams'),
        })
      }
      return teams
    },
    getTeamLabel (team) {
      return `${this.$t('tables.headings.code')}: ${team.code}`
    },
    initialData () {
      this.asyncFindTeams('&sort=code&direction=asc')
    },
    async asyncFindTeams (query) {
      query = encodeURI(query)
      if (!query.includes('&sort=')) {
        query += '&sort=code'
      }

      await this.asyncFind(query, 'teams')
      if (this.isError) {
        return this.asyncFindTeams(query)
      }

      if (this.currentUser.team) {
        // await this.getUserTeam(this.currentUser.team.id)
      }
    },
    async asyncFind (query, route) {
      this.isLoading = true
      this.isError = false

      if (!query.includes('&sort=')) {
        query += '&sort=code'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }
      if (!query.includes('&limit=')) {
        query += '&limit=30'
      }
      this.currentQuery = query

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading = false
        this.isError = true
        return
      }

      if (query !== this.currentQuery) return
      this.teamsList = this.fixData(response.data.data) || []
      this.isLoading = false
    },
    async getUserTeam (teamId) {
      if (!this.currentUser.can('Teams', 'view')) return
      this.isLoading = true
      this.isError = false

      let response = false
      try {
        response = await this.$http.get('/teams/' + teamId)
      } catch (e) {
        this.isLoading = false
        this.isError = true
        return
      }

      this.selectedTeam = response.data.data
      this.selectedTeam.label = this.getTeamLabel(this.selectedTeam)
      this.isLoading = false
      this.isError = false
    },
    teamSelected (team) {
      this.selectedTeam = team
      this.$emit('selected-team', this.selectedTeam)
    },
  },
}
</script>
